/// <reference types="vite/client" />

import { AttributeObserver } from '@hotwired/stimulus'
import type { ControllerModule } from './helpers'
import {
  CONTROLLER_ATTRIBUTE,
  extractControllerNamesFrom,
  modulesByControllerName,
} from './helpers'

// All the available controllers in the world
export const AVAILABLE_CONTROLLERS = modulesByControllerName(
  import.meta.glob<ControllerModule>([
    '../**/*_controller.js',
    '../../../components/**/*_controller.js',
  ])
)

//-- Lazy load all controllers that are inside the element's descendant's data-controller attributes --
function lazyLoadControllersInside(element: Element) {
  const controllerNames = Array.from(
    element.querySelectorAll(`[${CONTROLLER_ATTRIBUTE}]`)
  )
    .map(extractControllerNamesFrom)
    .flat()
  const uniqControllerNames = new Set(controllerNames)
  return uniqControllerNames.forEach((controllerName) =>
    AVAILABLE_CONTROLLERS[controllerName]?.()
  )
}

//-- Lazy load all controllers inside the element's data-controller attribute --
function lazyLoadControllersForElement(element: Element) {
  const uniqControllerNames = new Set(extractControllerNamesFrom(element))
  return uniqControllerNames.forEach((controllerName) =>
    AVAILABLE_CONTROLLERS[controllerName]?.()
  )
}

//-- Watches the given element (and it's descendants) for changes in it's child list or attributes, and lazy load new controller as soon as required --
function lazyLoadControllersOnChange(element: Element) {
  const observer = new AttributeObserver(element, CONTROLLER_ATTRIBUTE, {
    elementMatchedAttribute: lazyLoadControllersForElement,
    elementAttributeValueChanged: lazyLoadControllersForElement,
  })
  observer.start()
}

export function initializeLazyLoader() {
  lazyLoadControllersInside(document.documentElement)
  lazyLoadControllersOnChange(document.documentElement)
}
